'use strict';
app.factory('authService', ['$rootScope', '$http', '$q', '$injector', function ($rootScope, $http, $q, $injector)
{
    class authenticationInfo
    {
        token = "";
        isAuth = false;
        userName = "";
        email = "";
        userID = "";
        defaultOUKey = "";
        defaultOUCode = "";
        defaultCompKey = "";
        defaultDeptKey = "";
        rememberMe = false;
        clientID = "";
        userKey = -1;
        isLMStaff = false;
        enableBaitulmal = false;
        isAvgABWByPeriod = false;
        isTaxContCompulsory = false;
        isTaxEditable = true;
        isAutomatedOUAllocation = false;
        MasterNoPolicy = "";
        isShowAddRemEPF = false;
        isDemo = false;
        ClientKey = -1;
        isShowDivider = false;
        WBWtDisplayMtd = "";
        ApprovePRBy = "Item";
        ApproveCRBy = "Item";
        TimeZoneOffset = 0;
    };

    let authServiceFactory = {
        requireResetPassword: (strEmail) => $http.post($rootScope.masterUrlLogin + 'api/account/requireResetPassword?email=' + strEmail),
        validateUserEmail: (strEmail) => $http.post($rootScope.masterUrlLogin + 'api/account/validateUserEmail?email=' + strEmail),
        createUserPassword: (strEmail) => $http.post($rootScope.masterUrlLogin + 'api/account/createUserPassword?email=' + strEmail),
        createQuartoAppUserPassword: (strEmail) => $http.post($rootScope.masterUrlLogin + 'api/account/createQuartoAppUserPassword?email=' + strEmail),
        resetPassword: (strEmail, newPassword) => $http({
            url: $rootScope.masterUrlLogin + 'api/account/resetPasswordThruLink?email=' + strEmail,
            method: "POST",
            data: { newPassword: newPassword },
            headers: { 'Content-Type': 'application/json' }
        }),
        validateResetLink: (strEncrypted) => $http.post($rootScope.masterUrlLogin + 'api/account/validateResetLink?encrypted=' + strEncrypted).then(function (response) { return response; }, function (error) { return error; }),
        unlockUsersEmail: function (strEmail)
        {
            return new Promise((resolve, reject) =>
            {
                //check if localStorage supported
                if (window.localStorage)
                {
                    if (typeof window.localStorage.getItem(strEmail + "_last_alert") == "undefined" || window.localStorage.getItem(strEmail + "_last_alert") == null)
                    {
                        window.localStorage.setItem(strEmail + "_last_alert", (new Date()).getTime().toString());

                        $http.post($rootScope.masterUrlLogin + 'api/account/UnlockUsersEmail?email=' + strEmail).then(function ()
                        {
                            resolve();
                        }, function ()
                        {
                            reject();
                        });
                    }
                    else
                    {
                        //pretend to send alert successfully, but ignore saja
                        resolve();
                    }
                }
                else
                {
                    //pretend to send alert successfully, but ignore saja
                    resolve();
                }
            });
        },
        unlockUsers: (strEncrypted) => $http.post($rootScope.masterUrlLogin + 'api/account/unlockUsers?encrypted=' + strEncrypted).then(function (response) { return response; }, function (error) { return error; }),
        getClient: (strEmail) => $http.get($rootScope.masterUrlLogin + 'api/account/GetClient?email=' + strEmail),
        authentication: new authenticationInfo(),
        logOut: function ()
        {
            let that = this;

            that.authentication = new authenticationInfo();
            that.clearSession();
        },
        login: function (loginData)
        {
            loginData.userName = loginData.userName.toLowerCase();

            let that = this;
            let data = "grant_type=password&client_id=" + loginData.clientID + "&username=" + loginData.userName + "&medium_type=web&password=" + encodeURIComponent(loginData.password);
            let deferred = $q.defer();

            if (loginData.clientID == "")
            {
                deferred.reject({ error_description: "Client cannot be empty" });
            }
            else
            {
                //make sure it is clean before login
                that.logOut();

                $http.post($rootScope.masterUrlLogin + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response)
                {
                    that.validateGoogleAuthenticatorPIN(loginData.userName, document.getElementById("loginTwoFactorPIN")?.value, response.access_token).then(() =>
                    {
                        if (document.getElementById("loginTwoFactorPIN"))
                        {
                            document.getElementById("loginTwoFactorPIN").value = '';
                        }

                        that.authentication.token = response.access_token;

                        $http.get($rootScope.masterOdataUrlLogin + "User?UserEmail='" + loginData.userName + "'&dummy=1&$format=json&$select=UserID,UserName,DefaultOUKey,DefaultOUCode,DefaultCompKey,DefaultDeptKey,ClientID,IsLMStaff,EnableBaitulmal,isAvgABWByPeriod,IsTaxContCompulsory,IsTaxEditable,IsAutomatedOUAllocation,MasterNoPolicy,IsShowAddRemEPF,IsDemo,UserKey,ClientKey,IsShowDivider,IsSubLMUser,WBWtDisplayMtd,ApprovePRBy,ApproveCRBy,TimeZoneOffset").success(function (rsp)
                        {
                            if (rsp != null)
                            {
                                that.authentication.token = response.access_token;
                                that.authentication.isAuth = true;
                                that.authentication.userName = rsp.UserName;
                                that.authentication.email = loginData.userName;
                                that.authentication.userID = rsp.UserID;
                                that.authentication.defaultOUKey = rsp.DefaultOUKey;
                                that.authentication.defaultOUCode = rsp.DefaultOUCode;
                                that.authentication.defaultCompKey = rsp.DefaultCompKey;
                                that.authentication.defaultDeptKey = rsp.DefaultDeptKey;
                                that.authentication.rememberMe = false;
                                that.authentication.clientID = rsp.ClientID;
                                that.authentication.userKey = rsp.UserKey;
                                that.authentication.isLMStaff = rsp.IsLMStaff ? rsp.IsLMStaff : rsp.IsSubLMUser;
                                that.authentication.enableBaitulmal = rsp.EnableBaitulmal;
                                that.authentication.isAvgABWByPeriod = rsp.isAvgABWByPeriod;
                                that.authentication.isTaxContCompulsory = rsp.IsTaxContCompulsory;
                                that.authentication.isTaxEditable = rsp.IsTaxEditable;
                                that.authentication.isAutomatedOUAllocation = rsp.IsAutomatedOUAllocation;
                                that.authentication.MasterNoPolicy = rsp.MasterNoPolicy;
                                that.authentication.isShowAddRemEPF = rsp.IsShowAddRemEPF;
                                that.authentication.isDemo = rsp.IsDemo;
                                that.authentication.ClientKey = rsp.ClientKey;
                                that.authentication.isShowDivider = rsp.IsShowDivider;
                                that.authentication.WBWtDisplayMtd = typeof rsp.WBWtDisplayMtd != "string" ? "" : rsp.WBWtDisplayMtd;
                                that.authentication.ApprovePRBy = rsp.ApprovePRBy;
                                that.authentication.ApproveCRBy = rsp.ApproveCRBy;
                                that.authentication.TimeZoneOffset = rsp.TimeZoneOffset;

                                that.setSession();

                                //remove last sent
                                if (window.localStorage)
                                {
                                    window.localStorage.removeItem(that.authentication.email + "_last_alert");
                                }

                                deferred.resolve(response);
                            }
                            else
                            {
                                that.logOut();
                                deferred.reject("Cannot find user information");
                            };
                        }).error(function (err, status)
                        {
                            that.logOut();
                            deferred.reject(err);
                        });
                    }, (err) =>
                    {
                        that.logOut();
                        deferred.reject(err);
                    });
                }).error(function (err, status)
                {
                    that.logOut();
                    deferred.reject(err);
                });
            }

            return deferred.promise;
        },
        check: function (loginData)
        {
            loginData.userName = loginData.userName.toLowerCase();

            let that = this;
            let data = "grant_type=password&username=" + loginData.userName + "&medium_type=web&password=" + encodeURIComponent(loginData.password);
            let deferred = $q.defer();

            //make sure it is clean before login
            that.logOut();

            $http.post($rootScope.masterUrlLogin + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response)
            {
                that.validateGoogleAuthenticatorPIN(loginData.userName, document.getElementById("loginTwoFactorPIN")?.value, response.access_token).then(() =>
                {
                    if (document.getElementById("loginTwoFactorPIN"))
                    {
                        document.getElementById("loginTwoFactorPIN").value = '';
                    }

                    that.authentication.token = response.access_token;

                    $http.get($rootScope.masterOdataUrlLogin + "User?UserEmail='" + loginData.userName + "'&dummy=1&$format=json&$select=UserID,UserName,DefaultOUKey,DefaultOUCode,DefaultCompKey,DefaultDeptKey,ClientID,IsLMStaff,EnableBaitulmal,isAvgABWByPeriod,IsTaxContCompulsory,IsTaxEditable,IsAutomatedOUAllocation,MasterNoPolicy,IsShowAddRemEPF,IsDemo,UserKey,ClientKey,IsShowDivider,IsSubLMUser,WBWtDisplayMtd,ApprovePRBy,ApproveCRBy,TimeZoneOffset").success(function (rsp)
                    {
                        if (rsp != null)                        {
                            that.authentication.token = response.access_token;
                            that.authentication.isAuth = true;
                            that.authentication.userName = rsp.UserName;
                            that.authentication.email = loginData.userName;
                            that.authentication.userID = rsp.UserID;
                            that.authentication.defaultOUKey = rsp.DefaultOUKey;
                            that.authentication.defaultOUCode = rsp.DefaultOUCode;
                            that.authentication.defaultCompKey = rsp.DefaultCompKey;
                            that.authentication.defaultDeptKey = rsp.DefaultDeptKey;
                            that.authentication.rememberMe = false;
                            that.authentication.clientID = rsp.ClientID;
                            that.authentication.userKey = rsp.UserKey;
                            that.authentication.isLMStaff = rsp.IsLMStaff ? rsp.IsLMStaff : rsp.IsSubLMUser;
                            that.authentication.enableBaitulmal = rsp.EnableBaitulmal;
                            that.authentication.isAvgABWByPeriod = rsp.isAvgABWByPeriod;
                            that.authentication.isTaxContCompulsory = rsp.IsTaxContCompulsory;
                            that.authentication.isTaxEditable = rsp.IsTaxEditable;
                            that.authentication.isAutomatedOUAllocation = rsp.IsAutomatedOUAllocation;
                            that.authentication.MasterNoPolicy = rsp.MasterNoPolicy;
                            that.authentication.isShowAddRemEPF = rsp.IsShowAddRemEPF;
                            that.authentication.isDemo = rsp.IsDemo;
                            that.authentication.ClientKey = rsp.ClientKey;
                            that.authentication.isShowDivider = rsp.IsShowDivider;
                            that.authentication.WBWtDisplayMtd = typeof rsp.WBWtDisplayMtd != "string" ? "" : rsp.WBWtDisplayMtd;
                            that.authentication.ApprovePRBy = rsp.ApprovePRBy;
                            that.authentication.ApproveCRBy = rsp.ApproveCRBy;
                            that.authentication.TimeZoneOffset = rsp.TimeZoneOffset;

                            that.setSession();

                            //remove last sent
                            if (window.localStorage)
                            {
                                window.localStorage.removeItem(that.authentication.email + "_last_alert");
                            }

                            deferred.resolve(response);
                        }
                        else
                        {
                            that.logOut();
                            deferred.reject("Cannot find user information");
                        }
                    }).error(function (err, status)
                    {
                        that.logOut();
                        deferred.reject(err);
                    });
                }, (err) =>
                {
                    that.logOut();
                    deferred.reject(err);
                });
            }).error(function (err, status)
            {
                that.logOut();
                deferred.reject(err);
            });

            return deferred.promise;
        },
        clearSession: function ()
        {
            if (window.sessionStorage)
            {
                window.sessionStorage.clear();
            }
        },
        setSession: function ()
        {
            let that = this;

            //set only if token exist, authorized and lmstaff
            if (window.sessionStorage && that.authentication.token && that.authentication.isAuth && that.authentication.isLMStaff)
            {
                window.sessionStorage.authentication = JSON.stringify(that.authentication);
            }
        },
        loadSession: function ()
        {
            let that = this;

            if (window.sessionStorage && window.sessionStorage.authentication)
            {
                let authentication = JSON.parse(window.sessionStorage.authentication);

                if (authentication && authentication.token && authentication.isAuth && authentication.isLMStaff)
                {
                    that.authentication.token = authentication.token;
                    that.authentication.isAuth = authentication.isAuth;
                    that.authentication.userName = authentication.userName;
                    that.authentication.email = authentication.email;
                    that.authentication.userID = authentication.userID;
                    that.authentication.defaultOUKey = authentication.defaultOUKey;
                    that.authentication.defaultOUCode = authentication.defaultOUCode;
                    that.authentication.defaultCompKey = authentication.defaultCompKey;
                    that.authentication.defaultDeptKey = authentication.defaultDeptKey;
                    that.authentication.rememberMe = authentication.rememberMe;
                    that.authentication.clientID = authentication.clientID;
                    that.authentication.userKey = authentication.userKey;
                    that.authentication.isLMStaff = authentication.isLMStaff;
                    that.authentication.enableBaitulmal = authentication.enableBaitulmal;
                    that.authentication.isAvgABWByPeriod = authentication.isAvgABWByPeriod;
                    that.authentication.isTaxContCompulsory = authentication.isTaxContCompulsory;
                    that.authentication.isTaxEditable = authentication.isTaxEditable;
                    that.authentication.isAutomatedOUAllocation = authentication.isAutomatedOUAllocation;
                    that.authentication.MasterNoPolicy = authentication.MasterNoPolicy;
                    that.authentication.isShowAddRemEPF = authentication.isShowAddRemEPF;
                    that.authentication.isDemo = authentication.isDemo;
                    that.authentication.ClientKey = authentication.ClientKey;
                    that.authentication.isShowDivider = authentication.isShowDivider;
                    that.authentication.WBWtDisplayMtd = authentication.WBWtDisplayMtd;
                    that.authentication.ApprovePRBy = authentication.ApprovePRBy;
                    that.authentication.ApproveCRBy = authentication.ApproveCRBy;
                    that.authentication.TimeZoneOffset = authentication.TimeZoneOffset;
                }
            }
        },
        validateGoogleAuthenticatorPIN: function (userEmail, twoFactorPIN, token)
        {
            return new Promise((resolve, reject) =>
            {
                if ((isEnableQR || false) == false)
                {
                    resolve();
                }
                else
                {
                    if ((userEmail ?? "").trim() === "")
                    {
                        reject();
                    }
                    else
                    {
                        $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/Authentication/IsSalty?token=" + token).then((salt) =>
                        {
                            if (JSON.parse(salt.data) === true)
                            {
                                if ((twoFactorPIN ?? "") == "")
                                {
                                    reject({ error: "Request PIN" });
                                }
                                else
                                {
                                    $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/Authentication/ValidatePIN?UserEmail=" + userEmail + "&TwoFactorPIN=" + twoFactorPIN).then((validation) =>
                                    {
                                        if (JSON.parse(validation.data) === true)
                                        {
                                            resolve();
                                        }
                                        else
                                        {
                                            reject({ error: "Invalid PIN" });
                                        }
                                    }, (err) =>
                                    {
                                        reject(err);
                                    });
                                }
                            }
                            else
                            {
                                resolve();
                            }
                        }, (err) =>
                        {
                            reject(err);
                        });
                    }
                }
            });
        }
    };

    //bypass angular circular dependency
    $rootScope.getAuthentication = function ()
    {
        return authServiceFactory.authentication;
    };

    $.ajaxPrefilter(function (options, originalOptions, jqXHR)
    {
        //Request header for the mainstream API isn't appropriate for the Geoserver
        //The security (authentication) of Geoserver will be added in future
        let strUrlGeoserverBase = "https://map.quarto.cloud:8443/geoserver/";
        let strUrlPowerBI = "http://lintramax-vm12.cloudapp.net:2112/login";
        let strUrlLocalhost = "http://localhost:8080/geoserver";
        let strUrlVM19 = "http://lintramax-vm19.cloudapp.net:8080/geoserver";

        let url = options.url.toLowerCase();

        if (url != strUrlPowerBI &&
            url.indexOf(strUrlGeoserverBase) == -1 &&
            url.indexOf(strUrlLocalhost) == -1 &&
            url.indexOf(strUrlVM19) == -1)
        {
            if (typeof authServiceFactory.authentication.token != "undefined" && authServiceFactory.authentication.token != null)
            {
                //add custom header to authorized request
                jqXHR.setRequestHeader("Authorization", "Bearer " + authServiceFactory.authentication.token);
                jqXHR.setRequestHeader("LangKey", $rootScope.userSetting ? $rootScope.userSetting.langKey : 1);
                jqXHR.setRequestHeader("ClientID", authServiceFactory.authentication.clientID);
                jqXHR.setRequestHeader("ClientKey", authServiceFactory.authentication.ClientKey);
                jqXHR.setRequestHeader("UserKey", authServiceFactory.authentication.userKey);
                jqXHR.setRequestHeader("DeviceTZOffset", deviceTimezoneOffset());
            }
        }

        //record retry count
        options.retryCount = (typeof options.retryCount == "undefined") ? 0 : options.retryCount;

        //custom deferred object to handle done/fail callbacks
        let defer = $.Deferred();

        //return if no error encountered
        jqXHR.done(defer.resolve);

        //request failed, attempt to use alternative domain
        jqXHR.fail((xhr, textStatus, errorThrown) =>
        {
            let args = Array.prototype.slice.call(arguments);

            let allowRetry = (xhr.status === 0);
            let url = options.url.toLowerCase();

            //skip specific case
            if (url.contains("/signalr/") || url.contains("/reports/") || url.contains("process") || url.contains("import"))
            {
                //skip for these as it is handled separately
                allowRetry = false;
            }
            else if (url.endsWith("/token"))
            {
                //skip if invalid grant => wrong password etc
                allowRetry = false;
            }
            else if (url.includes("authentication"))
            {
                //dont retry this as it is time sensitive
                allowRetry = false;
            }
            //else if ((options.url + (options.dataType == "json" ? (options.data) : "")).length > 2000)
            //{
            //    //skip retry if url way too long
            //    allowRetry = false;
            //    xhr.status = xhr.status === 0 ? 414 : xhr.status;
            //}

            if (xhr && (xhr.readyState === 0) && allowRetry && !window.location.href.contains("localhost") && xhr.statusText === "error" && textStatus !== "parsererror")
            {
                //retry for fixed number of times
                if (options.retryCount < 3)
                {
                    let alternativeOptions = $.extend({}, originalOptions, { retryCount: (options.retryCount + 1) });

                    //find alternative domain, skip telerik for now as it need be sticky toward instance to work
                    let module = Object.entries($rootScope.domainUrls).filter(item => item[0] != "Reconfigure" && item[0] != "DNSGroup" && item[0] != "Report").map(item => item[1]).find(item => item.findIndex(x => alternativeOptions.url.contains(x)) > -1);

                    //found module
                    if (typeof module != "undefined" && module.length > 0)
                    {
                        //first retry    0 -  10 s
                            //second retry  10 -  60 s
                            //third retry   60 - 120 s
                            //forth retry  120 - 180 s
                            //fifth retry  180 - 240 s
                        let delay =
                            options.retryCount == 4 ? (Math.floor(Math.random() * (240 - 180 + 1)) + 180)
                                : options.retryCount == 3 ? (Math.floor(Math.random() * (180 - 120 + 1)) + 120)
                                    : options.retryCount == 2 ? (Math.floor(Math.random() * (120 - 60 + 1)) + 60)
                                        : options.retryCount == 1 ? (Math.floor(Math.random() * (60 - 10 + 1)) + 10)
                                            : options.retryCount <= 0 ? (Math.floor(Math.random() * (10 - 0 + 1)) + 0)
                                                : 0;

                        $injector.get('$timeout')(function ()
                        {
                            //get current and next domain url
                            let index = module.findIndex(x => alternativeOptions.url.contains(x));
                            let selectedIndex = ((index + 1) >= module.length) ? 0 : (index + 1);

                            alternativeOptions.url = alternativeOptions.url.replace(module[index], module[selectedIndex]);

                            D(() => console.log("Redirect(AuthService) : " + xhr.status + " " + options.type + " " + options.url + " To : " + alternativeOptions.url + " Attempt : " + (options.retryCount + 1) + " Delay : " + (delay * 1000)));

                            $.ajax(alternativeOptions).done(defer.resolve);
                            //$.ajax(alternativeOptions).then(defer.resolve, defer.reject);
                        }, delay * 1000);
                    }
                    else
                    {
                        //failed, reject it
                        $rootScope.unauthorized = true;
                        $rootScope.$emit("CallReloginMethod", {});

                        defer.rejectWith(jqXHR, args);
                    }
                }
                else
                {
                    D(() => console.log("Redirect(AuthService) : " + xhr.status + " " + options.type + " " + options.url + " exceeded max retry limit."));

                    //failed, reject it
                    $rootScope.unauthorized = true;
                    $rootScope.$emit("CallReloginMethod", {});

                    defer.rejectWith(jqXHR, args);
                }
            }
            else
            {
                //failed, reject it
                $rootScope.unauthorized = true;
                $rootScope.$emit("CallReloginMethod", {});

                defer.rejectWith(jqXHR, args);
            }
        });

        //override the jqXHR's original promise functions with custom deferred
        return defer.promise(jqXHR);
    });

    return authServiceFactory;
}]);